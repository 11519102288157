/** {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}*/

#card-table {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

#card-table .card {
  position: absolute;
  display: inline-block;
  left: -1.9375em;
  top: -2.75em;
  width: 3.875em;
  height: 5.5em;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.15);
  box-shadow: 0 1px 1px rgba(0,0,0,0.15);
  cursor: default;
  will-change: transform;
}
#card-table .card:before,
#card-table .card:after {
  position: absolute;
  font-size: 0.7em;
  text-align: center;
  line-height: 1em;
  font-family: 'Ubuntu Condensed', sans-serif;
  white-space: pre-line;
  width: 0.75em;
  letter-spacing: -0.2em;
}
#card-table .card:before {
  top: 0.25em;
  left: 0;
}
#card-table .card:after {
  bottom: 0.2em;
  right: 0;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
#card-table .card .face {
  height: 100%;
  background-position: 50% 50%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
#card-table .card .back {
  position: absolute;
  background-image: url("faces/back.png");
  background-position: 50% 50%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#card-table .card.spades,
#card-table .card.clubs,
#card-table .card.joker {
  color: #000;
}
#card-table .card.hearts,
#card-table .card.diamonds,
#card-table .card.joker.rank3 {
  color: #d40000;
}
#card-table .card.joker.rank1:before,
#card-table .card.joker.rank2:before,
#card-table .card.joker.rank3:before,
#card-table .card.joker.rank1:after,
#card-table .card.joker.rank2:after,
#card-table .card.joker.rank3:after {
  content: "J\a O\a K\a E\a R";
  letter-spacing: 0;
  font-size: 0.4em;
  line-height: 0.4em;
  padding: 0.15em 0.05em;
  width: 0.5em;
}
#card-table .card.rank1:before,
#card-table .card.rank1:after {
  content: "A";
}
#card-table .card.rank2:before,
#card-table .card.rank2:after {
  content: "2";
}
#card-table .card.rank3:before,
#card-table .card.rank3:after {
  content: "3";
}
#card-table .card.rank4:before,
#card-table .card.rank4:after {
  content: "4";
}
#card-table .card.rank5:before,
#card-table .card.rank5:after {
  content: "5";
}
#card-table .card.rank6:before,
#card-table .card.rank6:after {
  content: "6";
}
#card-table .card.rank7:before,
#card-table .card.rank7:after {
  content: "7";
}
#card-table .card.rank8:before,
#card-table .card.rank8:after {
  content: "8";
}
#card-table .card.rank9:before,
#card-table .card.rank9:after {
  content: "9";
}
#card-table .card.rank10:before,
#card-table .card.rank10:after {
  content: "10";
}
#card-table .card.rank11:before,
#card-table .card.rank11:after {
  content: "J";
}
#card-table .card.rank12:before,
#card-table .card.rank12:after {
  content: "Q";
}
#card-table .card.rank13:before,
#card-table .card.rank13:after {
  content: "K";
}
#card-table .card.spades.rank1 .face {
  background-image: url("faces/0_1.svg");
}
#card-table .card.spades.rank2 .face {
  background-image: url("faces/0_2.svg");
}
#card-table .card.spades.rank3 .face {
  background-image: url("faces/0_3.svg");
}
#card-table .card.spades.rank4 .face {
  background-image: url("faces/0_4.svg");
}
#card-table .card.spades.rank5 .face {
  background-image: url("faces/0_5.svg");
}
#card-table .card.spades.rank6 .face {
  background-image: url("faces/0_6.svg");
}
#card-table .card.spades.rank7 .face {
  background-image: url("faces/0_7.svg");
}
#card-table .card.spades.rank8 .face {
  background-image: url("faces/0_8.svg");
}
#card-table .card.spades.rank9 .face {
  background-image: url("faces/0_9.svg");
}
#card-table .card.spades.rank10 .face {
  background-image: url("faces/0_10.svg");
}
#card-table .card.spades.rank11 .face {
  background-image: url("faces/0_11.svg");
}
#card-table .card.spades.rank12 .face {
  background-image: url("faces/0_12.svg");
}
#card-table .card.spades.rank13 .face {
  background-image: url("faces/0_13.svg");
}
#card-table .card.hearts.rank1 .face {
  background-image: url("faces/1_1.svg");
}
#card-table .card.hearts.rank2 .face {
  background-image: url("faces/1_2.svg");
}
#card-table .card.hearts.rank3 .face {
  background-image: url("faces/1_3.svg");
}
#card-table .card.hearts.rank4 .face {
  background-image: url("faces/1_4.svg");
}
#card-table .card.hearts.rank5 .face {
  background-image: url("faces/1_5.svg");
}
#card-table .card.hearts.rank6 .face {
  background-image: url("faces/1_6.svg");
}
#card-table .card.hearts.rank7 .face {
  background-image: url("faces/1_7.svg");
}
#card-table .card.hearts.rank8 .face {
  background-image: url("faces/1_8.svg");
}
#card-table .card.hearts.rank9 .face {
  background-image: url("faces/1_9.svg");
}
#card-table .card.hearts.rank10 .face {
  background-image: url("faces/1_10.svg");
}
#card-table .card.hearts.rank11 .face {
  background-image: url("faces/1_11.svg");
}
#card-table .card.hearts.rank12 .face {
  background-image: url("faces/1_12.svg");
}
#card-table .card.hearts.rank13 .face {
  background-image: url("faces/1_13.svg");
}
#card-table .card.clubs.rank1 .face {
  background-image: url("faces/2_1.svg");
}
#card-table .card.clubs.rank2 .face {
  background-image: url("faces/2_2.svg");
}
#card-table .card.clubs.rank3 .face {
  background-image: url("faces/2_3.svg");
}
#card-table .card.clubs.rank4 .face {
  background-image: url("faces/2_4.svg");
}
#card-table .card.clubs.rank5 .face {
  background-image: url("faces/2_5.svg");
}
#card-table .card.clubs.rank6 .face {
  background-image: url("faces/2_6.svg");
}
#card-table .card.clubs.rank7 .face {
  background-image: url("faces/2_7.svg");
}
#card-table .card.clubs.rank8 .face {
  background-image: url("faces/2_8.svg");
}
#card-table .card.clubs.rank9 .face {
  background-image: url("faces/2_9.svg");
}
#card-table .card.clubs.rank10 .face {
  background-image: url("faces/2_10.svg");
}
#card-table .card.clubs.rank11 .face {
  background-image: url("faces/2_11.svg");
}
#card-table .card.clubs.rank12 .face {
  background-image: url("faces/2_12.svg");
}
#card-table .card.clubs.rank13 .face {
  background-image: url("faces/2_13.svg");
}
#card-table .card.diamonds.rank1 .face {
  background-image: url("faces/3_1.svg");
}
#card-table .card.diamonds.rank2 .face {
  background-image: url("faces/3_2.svg");
}
#card-table .card.diamonds.rank3 .face {
  background-image: url("faces/3_3.svg");
}
#card-table .card.diamonds.rank4 .face {
  background-image: url("faces/3_4.svg");
}
#card-table .card.diamonds.rank5 .face {
  background-image: url("faces/3_5.svg");
}
#card-table .card.diamonds.rank6 .face {
  background-image: url("faces/3_6.svg");
}
#card-table .card.diamonds.rank7 .face {
  background-image: url("faces/3_7.svg");
}
#card-table .card.diamonds.rank8 .face {
  background-image: url("faces/3_8.svg");
}
#card-table .card.diamonds.rank9 .face {
  background-image: url("faces/3_9.svg");
}
#card-table .card.diamonds.rank10 .face {
  background-image: url("faces/3_10.svg");
}
#card-table .card.diamonds.rank11 .face {
  background-image: url("faces/3_11.svg");
}
#card-table .card.diamonds.rank12 .face {
  background-image: url("faces/3_12.svg");
}
#card-table .card.diamonds.rank13 .face {
  background-image: url("faces/3_13.svg");
}
#card-table .card.joker.rank1 .face {
  background-image: url("faces/4_1.svg");
}
#card-table .card.joker.rank2 .face {
  background-image: url("faces/4_2.svg");
}
#card-table .card.joker.rank3 .face {
  background-image: url("faces/4_3.svg");
}
