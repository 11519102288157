/*
 * Fonts
 */
 @font-face {
  font-family: "Ansley Display Black";
  src: url('assets/AnsleyDisplay/AnsleyDisplay-Black.ttf') format('truetype');
 }

/*
 * Loader
 */

.botrice-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: 
    linear-gradient(
      hsla(184deg, 56%, 40%, 0.75),
      hsla(184deg, 56%, 40%, 0.75)
    ),
    url(assets/felt-bnw.png);
}

.botrice-loader h1 {
  font-family: "Ansley Display Black";
  font-size: 6em;
  color: #fff;
}

/*
 * App-wide structure
 */

.root, .App {
  width: 100vw;
  height: 100vh;
}

.main-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.main-content {
  flex: 1 1 auto;
  width: 100%;
}

/*
 * Lobby
 */

.lobby-container {
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(
      hsla(57deg, 28%, 88%, 0.75),
      hsla(57deg, 28%, 88%, 0.75)
    ),
    url(assets/felt-bnw.png);
}

.ui.container.lobby-container {
  padding: 2em 4em;
  margin: 0;
  height: calc(100% - 2.85714286em);
}

.ui.container.lobby-container .card .content .header {
  font-family: "Montserrat", "Lato", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #4BA3A9;
}

.ui.container.lobby-container>.header {
  padding-top: 1em;
}

.ui.container.lobby-container .header.fancy {
  font-family: "Bungee Shade", "Montserrat", "Lato", Arial, Helvetica, sans-serif;
}

.ui.cards a.lobby-card {
  background: 
    linear-gradient(
      rgba(255, 255, 255, 0.70),
      rgba(255, 255, 255, 0.70)
    ),
    url(assets/textured-paper-small.jpg);
}

.ui.cards a.lobby-card:hover {
  background: 
    linear-gradient(
      rgba(255, 255, 255, 0.70),
      rgba(255, 255, 255, 0.70)
    ),
    url(assets/textured-paper-small.jpg);
}

.ui.cards a.lobby-card.new-game, .ui.card.lobby-card.new-game:hover {
  background: transparent;
  border: 3px dashed #4BA3A9;
}

.ui.cards a.lobby-card.new-game .image {
  background: transparent;
}

.ui.cards a.lobby-card.new-game .content {
  border: none;
}

.ui.cards a.lobby-card.new-game .content .header {
  text-align: center;
}

.ui.cards a.lobby-card .images {
  text-align: center;
}

.ui.cards a.lobby-card .images .rounded {
  border-radius: 500em;
}

.ui.cards a.lobby-card .divider {
  margin-top: .2em;
  margin-bottom: .5em;
}

/*
 * Play Area
 */

.ui.grid.play-grid {
  margin: 0;
  height: 100%;
}

.ui.grid.play-grid .row {
  padding: 0;
}

.play-area .game-status {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.play-area .game-status .header {
  color: #fff;
}

.play-area .helper .header {
  color: #fff;
}

.play-area .player-avatar {
  position: absolute;
  width: 4em;
  height: 4em;
  border-radius: 4em;
  left: calc(50% - 2em);
  top: calc(50% - 2em);
}

.play-area .player-avatar.glow {
  animation: avatar-glow 1s infinite alternate;
}

.play-area .player-avatar > img {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 4em;
}

@keyframes avatar-glow {
  from {
    box-shadow: 0 0 0 0 white;
  }
  to {
    box-shadow: 0 0 2px 5px yellow;
  }
}

/*
 * Semantic UI Overrides
 */
 
 .ui.menu div.header {
  font-family: "Ansley Display Black";
  padding: 0;
  font-size: 2em;
  position: absolute;
  height: 1.6em;
  left: 50%;
  transform: translateX(-50%);
 }
 
.ui.header {
  font-family: "Montserrat", "Lato", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.ui.grid > .row > .column.no-padding {
  padding: 0;
}

/*
 * UI Components
 */

.game-sidebar {
  font-family: "Montserrat", "Lato", Arial, Helvetica, sans-serif;
  background: 
    linear-gradient(
      hsla(57deg, 28%, 98%, 0.75),
      hsla(57deg, 28%, 98%, 0.75)
    ),
    url(assets/felt-bnw.png);
}

.game-sidebar .container:first-of-type {
  margin-top: 20px;
}

.game-sidebar .container {
  margin-top: 40px;
}

.game-sidebar .header.fancy {
  font-family: "Bungee Shade", "Montserrat", "Lato", Arial, Helvetica, sans-serif;
}

.game-sidebar .ui.segment {
  background-color: transparent;
}

.game-sidebar .ui.segment .row:first-of-type {
  margin-top: 14px;
}

.player-name {
  padding-left: 18px;
  font-family: "Montserrat";
  font-size: 1.2em;
  font-weight: 600;
}

.player-points {
  padding-top: .45em;
}

.avatar-status {
  display: inline-block;
  border-radius: 500rem;
}

.avatar-status > .ui.avatar.image {
  margin: 0;
}

.avatar-online {
  border: 4px solid #4aa1a7;
}

.avatar-offline {
  border: 4px solid #db6555;
}

.avatar-offline > img {
  filter: grayscale(100%);
}

/*
 * Cards
 */

.play-area {
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(
      hsla(184deg, 56%, 40%, 0.75),
      hsla(184deg, 56%, 40%, 0.75)
    ),
    url(assets/felt-bnw.png);
}

.glow {
  animation: glow-up 1s infinite alternate;
}

@keyframes glow-up {
  from {
    box-shadow: 0 0 0 0 white;
  }
  to {
    box-shadow: 0 0 2px 5px yellow;
  }
}

.pile-outline {
  position: absolute;
  display: inline-block;
  left: -1.9375em;
  top: -2.75em;
  width: 3.875em;
  height: 5.5em;
  background-color: transparent;
  border-radius: 4px;
  border: .1em dashed #fff;
  cursor: default;
  will-change: transform;
}

.helper-container {
  display: flex;
}

.helper {
  position: absolute;
  display: inline-flex;
  left: -8em;
  top: -2.75em;
  width: 16em;
  height: 5.5em;
  background-color: transparent;
  align-items: center;
}

.helper .animated {
  animation: bounce 1s infinite alternate;
}

.helper.alone {
  /*left: -4em;*/
  align-items: center;
  justify-content: center;
}

@keyframes bounce {
  from {
    left: 0em;
  }
  to {
    left: 0.5em;
  }
}